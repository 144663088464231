<template>
    <div class="col-6">
      <v-card class="col-md-12">
        <v-card-text>
            <v-col class="col-md-12">
              <v-carousel style="height: 350px !important;">
                <v-carousel-item
                  v-for="(item, index) in this.imoveis"
                  :key="index"
                >
                  <img :src="item.imagem" />
                </v-carousel-item>
              </v-carousel>
            </v-col>
          <v-row>
            <v-col cols="4">
              <h3>teste</h3>
              <p>teste</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        imoveis: [
          {
            titulo: "Apartamento 1",
            descricao: "Apartamento localizado no centro da cidade.",
            imagem: "https://via.placeholder.com/600x350",
          },
          {
            titulo: "Apartamento 2",
            descricao: "Apartamento localizado em uma região residencial.",
            imagem: "https://via.placeholder.com/600x350",
          },
        ],
      };
    },
  };
  </script>
  